<template>
  <div class="meeting_container" ref="ref1">
    <div class="meeting_player" ref="ref2">
      <player
        :data="meetingData?.class_info.content || []"
        :cfg="playerCfg"
        ref="player"
        #default="{ citems, cindex }"
      >
        <div class="meeting_player_area global_full_screen">
          <!-- <img :src="citems.content" :key="cindex"> -->
          <MeetingItem
            :pageItem="citems"
            :index="cindex"
            :coursewareId="meetingData?.meet_info.courseware_id"
            :meetId="meetingData?.meet_info.id"
            :meetInfo="meetingData?.meet_info"
          />
          <!-- <MeetingItem :key="cindex" :index="cindex"  /> -->
        </div>
      </player>
      <div
        class="meeting_time global_meeting_padding"
        v-if="!meetingData.meet_info?.is_test"
      >
        <div>{{ getMeetingTime() }}</div>
        <!-- <div v-if="meetingData?.meet_info.phase == 1">
          第{{ meetingData?.doctor_index + 1 }}页
        </div> -->
      </div>
      <div class="meeting_voice_play" v-if="!meetingData.meet_info.isDoctor">
        <voiceItem :play="true" v-if="playVoiceItem.play"></voiceItem>
        <voiceItem v-else></voiceItem>
      </div>
    </div>
    <div class="meeting_content" ref="ref3">
      <div class="meeting_info_block" ref="ref4">
        <meetingInfo :meetingData="meetingData"></meetingInfo>
      </div>
      <div class="meeting_chat_block" ref="ref5">
        <div class="meeting_chat_title global_container_center" ref="ref6">
          <span>{{ getTabTitle() }}</span>
        </div>
        <div class="meeting_chat_item" ref="contentItem" :style="getStyle()">
          <meetingChat
            @resetFastReplay="resetFastReplay"
            @talk="doTalk"
            @sendMessage="sendMessage"
            @setNextStep="setNextStep"
            :meetingData="meetingData"
            v-if="!loading && showMeetingChatArea()"
            @setWatchNextStep="setWatchNextStep"
            @setWatchNextPage="setWatchNextPage"
          ></meetingChat>
          <meetingCourse
            ref="meetingCourse"
            @playVoice="playVoice"
            @stopVoice="stopVoice"
            @sendMessage="sendMessage"
            @setNextStep="setNextStep"
            :meetingData="meetingData"
            v-if="!loading && !showMeetingChatArea() && !meetingData.is_test"
            @setWatchNextStep="setWatchNextStep"
            @setWatchNextPage="setWatchNextPage"
          ></meetingCourse>
          <meetingCoursePreview
            ref="meetingCoursePreview"
            @sendMessage="sendMessage"
            @unLockArea="unLockArea"
            @setWatchNextStep="setWatchNextStep"
            @setWatchNextPage="setWatchNextPage"
            @setNextStep="setNextStep"
            :meetingData="meetingData"
            v-if="!loading && !showMeetingChatArea() && meetingData.is_test"
            @addPage="addPage"
            @removePage="removePage"
            @savePromptContent="savePromptContent"
            @saveInfo="saveInfo"
          ></meetingCoursePreview>
        </div>
      </div>
      <!-- <div class="meeting_control_block" >
      </div> -->
      <popup
        v-if="showPopup"
        :cfg="config.popup"
        @doSure="reloadPage"
        @doClose="closePopup"
      >
        <div class="meeting_popup">
          您的网络已中断，请切换至稳定的网络后，点击“重新连接”
        </div>
      </popup>
      <audio class="meeting_audio" ref="audio" v-if="resetAudio"></audio>
      <div class="meeting_audio" v-if="resetAudio">
        <audio
          class="meeting_audio"
          preload="metadata"
          :src="audioUrl"
          :key="index"
          :ref="`audio${index + 1}`"
          v-for="(audioUrl, index) in getAudioList()"
        ></audio>
      </div>
    </div>
  </div>
</template>
<script>
import player from "@/components/player/player";
import popup from "@/components/unit/popup";
import meetingChat from "@/components/page/meeting/meetingChat";
import meetingCourse from "@/components/page/meeting/meetingCourse";
import meetingCoursePreview from "@/components/page/meeting/meetingCoursePreview";
import meetingInfo from "@/components/page/meeting/meetingInfo";
import voiceItem from "@/components/unit/voiceItem";
import websocket from "@/common/websockets";
import weixin from "@/common/weixin";
import recorderH5 from "@/common/recorderH5";
import recorderWx from "@/common/recorder";
import MeetingItem from "@/components/page/meeting/meetingItem";
import BenzAMRRecorder from "benz-amr-recorder";

// import BenzAMRRecorder from 'benz-amr-recorder'

export default {
  name: "meeting",
  data: () => {
    return {
      meetingData: {
        chat_info: {
          chat: [],
        },
        class_info: {
          content: [],
          question: [],
          title: "",
          type: "",
        },
        meet_info: {},
        online_count: 0,
        doctor_index: 0,
        live_index: 0,
      },
      resetAudio: true,
      config: {
        popup: {
          title: "友情提示",
          button: {
            sure: "重新连接",
            cancel: "取消",
          },
        },
        dealWithFunction: {
          meet_info: "dealWithMeetInfo",
          meet_chat: "dealWithMeetChat",
          meet_online: "dealWithMeetOnline",
          meet_offline: "dealWithMeeOffline",
          meet_control: "dealWithMeetControl",
          meet_next: "dealWithMeetNext",
          meet_error: "dealWithError",
        },
      },
      playerCfg: {
        sliding: false, // 支持滑动
        page: false, // 支持翻页
        point: false, // 支持跳转
      },
      timer: {
        meetingTime: {
          time: null,
          startTime: 0,
          count: 0,
        },
      },
      playVoiceItem: {
        play: false,
        list: [],
      },
      loading: true,
      showPopup: false,
      meetingId: "",
      videoURL: "",
      contentHeight: 0,
      isLockArea: true,
      isWatch: 0,
      isAdmin: 0,
      localId: "",
      amr: [],
      playIndex: 1,
      project: null,
    };
  },
  components: {
    player,
    voiceItem,
    meetingInfo,
    meetingChat,
    meetingCourse,
    meetingCoursePreview,
    MeetingItem,
    popup,
  },
  watch: {
    "$store.state.common.isConnect": function () {
      this.$store.state.common.isConnect
        ? this.closePopup()
        : (this.showPopup = true);
    },
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "肿瘤抗血管靶向治疗";
    // role 0 观众 1 专家
    this.meetingData.role =
      Number(this.$route.query.role) || this.$config.categary.role.guest;
    this.meetingData.is_test = Number(this.$route.query.is_test) || 0;
    this.isWatch = Number(this.$route.query.watch) || 0;
    this.isAdmin = Number(this.$route.query.admin) || 0;
    if (this.meetingData.role == 1 && this.meetingData.is_test == 0)
      this.loadData();
    else this.initMeeting();
  },
  mounted() {
    this.$nextTick(() => {
      // let scale = document.body.clientWidth/750;
      // this.contentHeight = `${this.$refs.contentItem.clientHeight/(30 * scale) * 4 }vw`
      this.contentHeight = `${this.$refs.contentItem.clientHeight}px`;
      this.printHeight();
    });
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.api.meetDetail, {
        meeting_number: this.$route.params.id,
      });
      this.$axios.get(url).then((res) => {
        let data = res.data;
        if (data.is_test == 0 || this.isWatch || data.audit_status != 0) {
          this.initMeeting();
          return;
        }
        this.meetingId = data.id;
        // this.showPopup = true;
        this.clearMeetingData();
      });
    },
    printHeight() {
      this.$nextTick(() => {
        // console.log(`ref1:width=${this.$refs.ref1.clientWidth};height=${this.$refs.ref1.clientHeight}`)
        // console.log(`ref2:width=${this.$refs.ref2.clientWidth};height=${this.$refs.ref2.clientHeight}`)
        // console.log(`ref3:width=${this.$refs.ref3.clientWidth};height=${this.$refs.ref3.clientHeight}`)
        // console.log(`ref4:width=${this.$refs.ref4.clientWidth};height=${this.$refs.ref4.clientHeight}`)
        // console.log(`ref5:width=${this.$refs.ref5.clientWidth};height=${this.$refs.ref5.clientHeight}`)
        // console.log(`ref6:width=${this.$refs.ref6.clientWidth};height=${this.$refs.ref6.clientHeight}`)
        // console.log(`contentItem:width=${this.$refs.contentItem.clientWidth};height=${this.$refs.contentItem.clientHeight}`)
      });
    },
    closePopup() {
      this.showPopup = false;
    },
    reloadPage() {
      window.location.reload();
    },
    // 测试会议需要清除会议数据
    clearMeetingData() {
      let url = this.$tools.getURL(this.$urls.meet.reset, {
        id: this.meetingId,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.showPopup = false;
          this.initMeeting();
        })
        .catch((err) => {
          this.$router.back();
        });
    },
    getAudioList() {
      if (!this.meetingData || !this.meetingData.chat_info) return [];
      let chat = this.meetingData.chat_info.chat || [];
      let list = chat
        .map((x) => x.voice_path)
        .filter((x) => x && /\.mp3$/.test(x));
      if (list.length < 20) {
        let temp = new Array(20 - list.length).fill("");
        list = list.concat(temp);
      }
      return list;
    },
    initMeeting() {
      let url = this.$tools.getURL(this.$urls.meet.project, {
        meet_number: this.$route.params.id,
      });
      this.$axios.get(url).then((res) => {
        this.project = res.data;
        this.recorder = this.project.record_module ? recorderH5 : recorderWx;
        if (this.isWatch) {
          this.initWebsocket();
        } else {
          this.recorder.init(weixin, () => {
            this.initWebsocket();
          });
        }
      });
    },
    initWebsocket() {
      websocket.initWebsocket(this.$route.params.id, this.dealWithCallback, {
        role: this.meetingData.role,
        is_test: this.meetingData.is_test,
        watch: this.isWatch,
        openid: this.$route.query.openid || this.$tools.getCookie("openid"),
      });
    },
    addPage(idx, item) {
      this.meetingData.class_info.content.splice(idx, 0, item);
      this.meetingData.class_info.content.forEach((val, index) => {
        this.meetingData.class_info.content[index].page = index + 1;
      });
      this.dealWithMeetNext(
        {
          doctor_index: idx,
          live_index: idx - 1,
        },
        true
      );
    },
    savePromptContent(index, content) {
      this.meetingData.class_info.content[index].prompt_content = content;
    },
    saveInfo(index, data) {
      if (!data) return;
      this.meetingData.class_info.content[index].content = data.content;
    },
    removePage(idx) {
      this.meetingData.class_info.content.splice(idx, 1);
      this.meetingData.class_info.content.forEach((val, index) => {
        this.meetingData.class_info.content[index].page = index + 1;
      });
      let doctorIndex = idx == 0 ? idx : idx - 1;
      let liveIndex = doctorIndex == 0 ? doctorIndex : doctorIndex - 1;
      this.dealWithMeetNext({
        doctor_index: doctorIndex,
        live_index: liveIndex,
      });
    },
    dealWithCallback(response) {
      let dealWithFunction = this.config.dealWithFunction[response.type];
      if (dealWithFunction) {
        if (!["meet_online", "meet_offline"].includes(response.type))
          console.log(response);
        this[dealWithFunction](response.data);
      }
    },

    dealWithError(data) {
      this.$tips.error({ text: data.msg });
    },
    dealWithMeetInfo(data) {
      let content = data.class_info.content;
      let systemIndex = 1;
      let isTest = Number(this.$route.query.is_test) || 0;
      content.forEach((val, index) => {
        if (
          val.sys_add &&
          ["COMMONTEMPLATE", "SYSTEMCOMMONTEMPLATE"].includes(val.template_key)
        ) {
          content[index].systemIndex = systemIndex;
          systemIndex += 1;
        }
      });
      data.class_info.content = content;
      if (this.isWatch) {
        data.meet_info.phase = 1;
        data.doctor_index = 0;
        data.live_index = 0;
      } else if (isTest) {
        let item = this.$tools.getStorage("TestMeetInfo", sessionStorage);
        if (item) {
          data.doctor_index = item.doctor_index;
          data.live_index = item.live_index;
          data.meet_info.phase = item.phase;
        }
      }
      if (data.meet_info.status <= 1) {
        data.doctor_index =
          data.doctor_index >= data.class_info.content.length
            ? data.class_info.content.length - 1
            : data.doctor_index;
      }
      this.meetingData = {
        ...data,
        project: this.project,
        isWatch: this.isWatch,
        isAdmin: this.isAdmin,
        is_test: isTest,
      };
      console.log(`this.meetingData===>`, this.meetingData);
      this.meetingData.meet_info.isDoctor =
        this.meetingData.role === this.$config.categary.role.doctor;
      // this.meetingData.meet_info.phase = 1;
      // this.meetingData.meet_phase = [{
      //   phase_time:"2021-11-30 17:17:00"
      // }]

      // weixin.shareMessage({
      //     title:this.meetingData.meet_info.name,
      //     description:`主持专家：${ this.meetingData.meet_info.doctor_name}\n${this.meetingData.meet_info.start_time}`,
      //     url:window.location.url
      // })
      this.goToCoursePage();
      // 如果没有开始或已结束，则不开启倒计时
      if (
        ![
          this.$config.categary.phase.meeting_start,
          this.$config.categary.phase.meeting_end,
        ].includes(this.meetingData.meet_info.phase)
      ) {
        this.initMeetingTime();
      }
      this.loading = false;
      if (
        this.meetingData.meet_info.isDoctor &&
        this.meetingData.meet_info.phase < 2
      ) {
        this.$nextTick(() => {
          let key = this.meetingData.is_test
            ? "meetingCoursePreview"
            : "meetingCourse";
          this.$refs[key] && this.$refs[key].initCourseStatus(this.meetingData);
        });
      }
    },
    dealWithMeetChat(data) {
      console.log(data);
      if (data.doctor_index < this.meetingData.class_info.content.length) {
        let prepChat = this.meetingData.chat_info.chat.filter(
          (x) => x.voice_page < data.doctor_index + 1
        );
        let nextChat = this.meetingData.chat_info.chat.filter(
          (x) => x.voice_page > data.doctor_index + 1
        );
        this.meetingData.chat_info.chat = prepChat.concat(data, nextChat);
      } else {
        this.meetingData.chat_info.chat.push(data);
      }
      // this.sendMessage("meet_log",data);
      if (
        !this.meetingData.meet_info.isDoctor &&
        !this.$tools.isLocal() &&
        data.voice_length > 0
      ) {
        this.playVoice(data);
      }
    },
    dealWithMeetOnline(data) {
      this.meetingData.online_count = Number(data.online_count);
      this.meetingData.meet_info.is_online = data.is_online;
    },
    dealWithMeeOffline(data) {
      this.meetingData.online_count = Number(data.online_count);
      this.meetingData.meet_info.is_online = data.is_online;
    },
    dealWithMeetControl(data) {
      if (!this.isWatch) this.meetingData.meet_phase.push(data);
      this.meetingData.meet_info.phase = data.phase;
      if (data.phase == this.$config.categary.phase.meeting_course) {
        this.initMeetingTime();
        this.saveTestMeetInfo();
        return;
      }
      if (data.phase == this.$config.categary.phase.meeting_discuss) {
        this.meetingData.doctor_index =
          this.meetingData.class_info.content.length - 1;
        this.meetingData.live_index =
          this.meetingData.class_info.content.length - 1;
        this.goToCoursePage();
        this.saveTestMeetInfo();
        return;
      }
      this.saveTestMeetInfo();
      if (data.phase == this.$config.categary.phase.meeting_end) {
        this.meetingData.chat_info.chat.push({
          color: "gray",
          type: "system",
          content: "会议结束",
        });
        if (this.meetingData.meet_info.isDoctor) {
          this.$router.push({
            path: "/meetDetail",
            query: {
              id: this.meetingData.meet_info.id,
            },
          });
        } else {
          // weixin.wx.closeWindow()
        }
        return;
      }
    },
    dealWithMeetNext(data, isEdit = false) {
      this.meetingData.doctor_index = Number(data.doctor_index);
      this.meetingData.live_index = Number(data.live_index);
      console.log(this.meetingData.doctor_index);
      this.goToCoursePage();
      let key = this.meetingData.is_test
        ? "meetingCoursePreview"
        : "meetingCourse";
      this.$nextTick(() => {
        this.$refs[key] &&
          this.$refs[key].initNextCourse(this.meetingData, isEdit);
      });
      this.saveTestMeetInfo();
    },
    saveTestMeetInfo() {
      if (!this.meetingData.is_test) return;
      let item = this.$tools.getStorage("TestMeetInfo", sessionStorage) || {};
      item.doctor_index = this.meetingData.doctor_index;
      item.live_index = this.meetingData.live_index;
      item.phase = this.meetingData.meet_info.phase;
      this.$tools.setStorage("TestMeetInfo", item, sessionStorage);
    },
    removeVoice() {},
    async playVoice(data, isRecord = false) {
      this.playVoiceItem.list.push({ ...data, isRecord });
      if (this.playVoiceItem.play) {
        return;
      }
      this.resetAudio = false;
      let item = this.playVoiceItem.list.shift();
      this.resetAudio = true;
      if (!item.voice_id) return;
      // let result = await recorder.download(item);
      this.videoURL = item.localId || item.voice_path;
      this.playVoiceItem.play = true;
      this.$nextTick(() => {
        this.playItem(item);
      });
    },
    isAmr() {
      return /\.(amr)|(mp3)$/.test(this.videoURL);
    },
    playItem(item) {
      this.playIndex += 1;
      this.videoURL = item.localId || item.voice_path;
      console.log(this.isAmr(), this.videoURL, item.voice_path, item);
      this.isAmr() ? this.amrPlay(item) : this.localIdPlay(item);
    },
    amrPlay(item) {
      console.log(33333);
      let playIndex = this.playIndex;
      let isMp3 = this.videoURL.includes("mp3");
      let execute = isMp3 ? this.getMp3 : this.getAmr;
      execute((audio) => {
        console.log(this.videoURL);
        this.amr[playIndex] = audio;
        if (isMp3) {
          this.amr[playIndex].start();
        } else this.amr[playIndex].play();
        // if (isMp3 && !item.isRecord) {
        //   try {
        //     console.log("是mp3",this.amr[playIndex])
        //     this.amr[playIndex].onended =  () => {
        //       this.playEnd(playIndex);
        //     }
        //   } catch (err) {
        //     console.log("而且报错了",err)
        //     setTimeout(() => {
        //       this.playEnd(playIndex);
        //     }, (item.voice_length + 1) * 1000);
        //   }
        // } else {
        //   setTimeout(() => {
        //     this.playEnd(playIndex);
        //   }, (item.voice_length + 1) * 1000);
        // }

        setTimeout(() => {
          this.playEnd(playIndex);
        }, (item.voice_length + 1) * 1000);
      }, item);
    },
    playEnd(playIndex) {
      if (this.amr[playIndex] && this.playIndex == playIndex) {
        this.amr[playIndex].stop && this.amr[playIndex].stop();
        this.amr[playIndex].pause && this.amr[playIndex].pause();
      }
      // this.amr[playIndex].stop()
      this.amr[playIndex] = null;
      this.playVoiceItem.play = false;
      if (this.playVoiceItem.list.length > 0) {
        this.playVoice(this.playVoiceItem.list.shift());
      }
    },
    getMp3(callback, item) {
      // if (!item.isRecord) {
      //   // let random = Math.floor(Math.random()*20)
      //   let random = item.voice_page
      //   let audio = this.$refs[`audio${random}`]
      //   console.log(audio)
      //   console.log(audio.src)
      //   if(audio instanceof Array){
      //     audio = audio[0]
      //   }
      //   if(!this.isWatch){
      //     audio.playsinline = "playsinline";
      //     audio.src = this.videoURL;
      //   }
      //   callback(audio);
      //   return;
      // }
      // 创建一个 AudioContext 对象
      const audioContext = new AudioContext();
      // 创建一个 GainNode 来控制音量
      const gainNode = audioContext.createGain();
      // 创建一个 AudioBufferSourceNode 来播放音频文件
      const sourceNode = audioContext.createBufferSource();
      // 加载音频文件并解码
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.videoURL, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = () => {
        audioContext.decodeAudioData(xhr.response, (buffer) => {
          // 将音频数据设置为 AudioBufferSourceNode 的 buffer
          sourceNode.buffer = buffer;
          sourceNode.connect(gainNode);
          gainNode.connect(audioContext.destination);

          // 播放音频文件
          callback(sourceNode);
        });
      };
      xhr.send();
    },
    getAmr(callback) {
      let amr = new BenzAMRRecorder();
      amr.initWithUrl(this.videoURL).then(() => {
        callback(amr);
      });
    },
    localIdPlay(item) {
      this.recorder.playVoice(this.videoURL);
      setTimeout(() => {
        this.playVoiceItem.play = false;
        this.videoURL = "";
        if (this.playVoiceItem.list.length > 0) {
          this.playVoice(this.playVoiceItem.list.shift());
        }
      }, (item.voice_length + 1) * 1000);
    },
    stopVoice() {
      if (!this.videoURL) return;
      this.playIndex += 1;
      this.playVoiceItem.list = [];
      try {
        if (this.isAmr()) {
          let index = 0;
          while (index < this.amr.length) {
            if (this.amr[index]) {
              // this.amr[index].stop()
              this.amr[index].stop && this.amr[index].stop();
              this.amr[index].pause && this.amr[index].pause();
              this.amr[index] = null;
            }
            index += 1;
          }
        } else {
          this.recorder.stopVoice(this.videoURL);
        }
      } catch (err) {
        console.log(err);
      }
      this.playVoiceItem.play = false;
      this.videoURL = "";
    },
    sendMessage(type, data) {
      // let phaseItem = this.meetingData.meet_phase[2];
      console.log(type, data);
      console.log("发送出去了");
      // if( type != "meet_chat" || !phaseItem){
      //   websocket.request(type,data)
      //   return;
      // }
      // 在会议结束 一小时后，任何角色都不可以发言了
      // if(this.$tools.getTime(phaseItem.phase_time) + 60 * 60 * 1000  < this.$tools.getTime() ) return;
      websocket.request(type, data);
    },
    setNextStep(data) {
      this.sendMessage("meet_control", data);
    },
    setWatchNextStep(index) {
      let data = this.meetingData.meet_phase[index - 1] || { phase: index };
      this.dealWithMeetControl(data);
    },
    setWatchNextPage(data) {
      this.dealWithMeetNext(data);
    },
    showMeetingChatArea() {
      // true 显示chat,false 限制提词器
      if (!this.meetingData.meet_info.isDoctor) return true;
      return this.meetingData.meet_info.phase >= 2;
    },
    resetFastReplay(data) {
      this.meetingData.class_info.question = [].concat(data);
    },
    // 会议时间记录
    initMeetingTime() {
      if (this.timer.meetingTime.time) {
        clearTimeout(this.timer.meetingTime.time);
        this.timer.meetingTime.time = null;
      }
      let count = this.$tools.getTime(
        this.meetingData.meet_phase[0]?.phase_time || ""
      );
      count = count - (count % 1000);
      if (!this.meetingData.meet_info.isDoctor) {
        count -= 1000;
      }
      this.timer.meetingTime.count =
        this.$tools.getTime() -
        this.$tools.getTime(this.meetingData.meet_phase[0]?.phase_time || "");
      this.timer.meetingTime.time = setTimeout(() => {
        this.initMeetingTime();
      }, 1000);
    },
    goToCoursePage() {
      let key = this.meetingData.meet_info.isDoctor
        ? "doctor_index"
        : "live_index";
      this.$refs.player.goToPage(this.meetingData[key]);
    },
    unLockArea() {
      this.isLockArea = false;
    },
    getStyle() {
      // 如果你发现有超屏现象，这是关键点
      if (this.isLockArea) return;
      return `height:${this.contentHeight}`;
    },
    getTabTitle() {
      if (this.showMeetingChatArea()) {
        return this.$tools.isPravate() ? "预备提交" : "讨论区";
      }
      return "解说词区";
    },
    getMeetingTime() {
      if (
        this.meetingData.meet_info.phase ==
          this.$config.categary.phase.meeting_start ||
        !this.meetingData?.meet_info.phase
      )
        return "未开始";
      if (
        this.meetingData.meet_info.phase ==
          this.$config.categary.phase.meeting_end ||
        this.meetingData.meet_info.status == 2
      )
        return "已结束";
      let count =
        this.timer.meetingTime.count > 0 ? this.timer.meetingTime.count : 0;
      return this.$tools.getShowTime(count);
    },
    goToNextPage() {
      // this.$refs.player.goToNextPage()
      websocket.request("meeting_next", { index: 4 });
    },
    doTalk(data) {
      websocket.request("meet_chat", data);
    },
  },
  beforeUnmount() {
    this.playVoiceItem.list = [];
    this.stopVoice();
    console.log("close websocket");
    websocket.close();
  },
};
</script>
<style>
.meeting_container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.meeting_player {
  flex: 100vw 0 0;
  width: 100%;
  position: relative;
}
.meeting_player_area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
/* .meeting_player_area img{
  height:100%;
  width:auto;
  max-width:100%;
} */
.meeting_time {
  width: 100%;
  height: 5.6vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2001;
  background: rgba(30, 30, 30, 0.1);
  color: #fff;
  font-size: 3.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meeting_time div {
  height: 3.2vw;
}
.meeting_content {
  flex: 1 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.meeting_info_block {
  flex: 8.53vw 0 0;
}
.meeting_chat_block {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.meeting_chat_title {
  flex: 8.26vw 0 0;
  font-weight: 400;
  background: #7fb8ff;
}
.meeting_chat_title span {
  display: inline-block;
  height: 4vw;
  font-size: 4vw;
}
.meeting_chat_item {
  flex: 1 0 0;
  /* min-height:50vw; */
  background: #e4f0fe;
}
.meeting_control_block {
  flex: 12vw 0 0;
  background: #fff;
}
.meeting_popup {
  width: 70vw;
  padding: 4vw;
  line-height: 6vw;
  word-break: break-all;
  text-align: center;
}
.meeting_voice_play {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 4vw;
  position: absolute;
  right: 0;
  top: 4vw;
  z-index: 1000;
  /* background:red; */
}
.meeting_audio {
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1000;
}
</style>
